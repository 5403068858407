/**
 * Return whether or not the browser supports web storage (localStorage and sessionStorage)
 *
 * @returns {boolean} true if the objects are accessible, false otherwise
 */
module.exports = function webStorageDetect() {
  try {
    // Test web storage existence.
    if (!window.localStorage || !window.sessionStorage) throw "exception";

    // Test web storage accessibility - Needed for Safari private browsing.
    localStorage.setItem('storage_test', 1);
    localStorage.removeItem('storage_test');
    return true;
  } catch(e) {
    return false;
  }
};
